.btn {
  // Add our margins and helper styling for any icon that's added to a button.
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    min-width: 100px;
    padding: $btn-padding-y $btn-padding-x;
    &:not(a) {
        box-shadow: 0 0 7px 1px $overlay-10;
    }
    span, i {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0.5rem;
    }
    span {
        margin-left: 0;
        padding: 0 5px;
    }
    &-toolbar {
        .btn {
            border-radius: $btn-border-radius;
            margin: {
                top:0;
                bottom: 0;
                right: 5px;
                left: 5px;
            }
        }
    }
    &-group {
        .btn {
            border-radius: $btn-border-radius;
        }
        > .btn:not(:last-child):not(.dropdown-toggle) {
            border-radius: $btn-border-radius;
        }
        > .btn:not(:first-child) {
            border-radius: $btn-border-radius;
            margin-left: 5px;
        }
    }
    &-primary {

    }
    &-secondary {
        background-color: $white;
        color: $green;
        border: 1px solid $green;
    }
    &-tertiary {
        background-color: $tertiary;
        color: white;
        &:hover {
            background-color: $tertiary-darker;
        }
    }
    &-field {
        background-color: $eggplant-light;
    }
    &-text {
        box-shadow: unset ! important;
        border: 0;
    }
    &-action {
        width: 35px;
        height: 35px;
        //border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        min-width: unset;
        border: unset ! important;
        box-shadow: none ! important;
        color: $eggplant;
        &.btn-secondary, &.btn-primary, &.btn-tertiary {
            padding: 0;
        }
    }
    &-dropdown {
        box-shadow: unset ! important;
    }
    &-toggle {
        $toggle-height: 30px;
        width: 65px;
        height: $toggle-height;
        border-radius: 50px;
        box-shadow: inset 0 0 2px 2px $overlay-05;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 7px;
        border: unset;
        background-color: $eggplant-light;
        font-size: 12px;
        &.on {
            background-color: $primary;
            .indicator {
                text-align: left;
            }
            .dial {
                right: 0;
            }
        }
        &.off {
            .indicator {
                text-align: right;
                color: $overlay-50;
            }
            .dial {
                left: 0;
            }
        }
        &:focus {
            outline: 0;
        }
        .indicator {
            text-transform: uppercase;
            display: block;
            width: 100%;
        }
        .dial {
            width: $toggle-height;
            height: $toggle-height;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            box-shadow: 0 0 2px 1px $overlay-15;
            background-color: $eggplant;
        }
    }
    &-table-sort {
        appearance: none;
        border: unset;
        background-color: unset;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        padding: 0;
        &:hover {
            text-decoration: underline;
        }
    }
}

.dropdown-combined {
    border-radius: $btn-border-radius;
    margin: 0 5px;
    box-shadow: $btn-box-shadow;
    overflow: hidden;
    button {
        margin: 0 ! important;
        border-radius: 0 ! important;
        box-shadow: none;
    }
    .dropdown-toggle {
        border-left: 1px solid $overlay-25;
        min-width: unset;
        padding-left: 0.2em;
        padding-right: 0.2em;
    }
}

.progress-nav {
    padding: $padding-main/2 $padding-main;
    display: flex;
    align-items: center;
    justify-content: center;
    &-item {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        border: 1px solid $eggplant;
        margin: 0 15px;
        &.selected {
            background-color: $eggplant;
        }
        &.disabled {
            border-color: $eggplant-light;
        }
    }
    a.progress-nav-item:hover {
        background-color: $primary;
        border-color: $primary;
    }
}

.dropdown-group {
    margin-bottom: 5px;
    h5 {
        padding: 0.25rem 0.75rem;
        font-weight: 900;
        //border-left: 3px solid $eggplant-light;
    }
}

.menu-link {
    text-decoration: underline;
}

button, [type=button], [type=reset], [type=submit] {
    appearance: unset;
}
