[class*="control"] > div:first-of-type {
    font-size: $font-size-base ! important;
    padding: $input-padding-y $input-padding-x;
    > div:last-of-type {
        padding-top: 0 ! important;
        padding-bottom: 0 ! important;
        margin-top: 0 ! important;
        margin-bottom: 0 ! important;
    }
}
[class*="control"] {
    border-radius: $input-border-radius ! important;
}


.form-group {
    //margin-bottom: 0;
    textarea {
        &.form-control {
            max-height: none;
            height: inherit !important;
        }
    }
    &.required {
        label {
            &:after {
                content: "*";
                color: $red;
                font-size: 0.65em;
                margin-left: 3px;
            }
        }
    }
    label {
        @include standard-label;
    }
    .form-control, .custom-select {
        padding: $input-padding-y $input-padding-x;
        height: unset;
        border-radius: $input-border-radius;
    }
    &.inline {
        label {
            margin-bottom: 0;
            margin-right: 5px;
        }
        &.reduced {
            [class*="container"] [class*="control"] {
                min-height: unset ! important;
            }
            .form-control, .custom-select, [class*="ValueContainer"] {
                font-size: 0.8rem ! important;
            }
            .form-control, [class*="ValueContainer"] {
                padding: 0.375rem 0.75rem ! important;
            }
            .custom-select {
                padding: 0.375rem 1.75rem 0.375rem 0.75rem;
            }
            [class*="indicatorContainer"] {
                padding: 0.2rem ! important;
            }
        }
    }
    @media (max-width: map-get($grid-breakpoints, 'md')) {
        //margin-bottom: 25px;
    }
}

.field-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    .col {
        //padding: 0 10px;
    }
    //.col:first-of-type {
    //    padding-left: 0;
    //}
    //.col:last-of-type {
    //    padding-right: 0;
    //}
    //[class^="col-"]:first-of-type {
    //    padding-left: 0;
    //}
    //[class^="col-"]:last-of-type {
    //    padding-right: 0;
    //}
    .form-group {
        margin-bottom: $spacer;
    }
    //@media (max-width: map-get($grid-breakpoints, 'md')) {
    //    .col-sm-12 {
    //        padding-left: 0;
    //        padding-right: 0;
    //    }
    //}
}

.react-datepicker-wrapper {
    width: 100%;
}

label.standard {
    @include standard-label;
}

.form-footer {
    background-color: $eggplant-mid;
    color: white;
    //display: flex;
    width: 100%;
    padding: 20px {
        top: 10px;
        bottom: 10px;
    };
    &.fixed-bottom {
        z-index: 987;
        padding-left: 80px;
    }
    .btn-text {
        color: $white;
    }
}
.option-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    //padding: 10px;
    //background-color: $eggplant-lightest;
    //border-radius: 50px;
    //box-shadow: inset 0 0 1px 1px $overlay-10;
    h5 {
        margin-bottom: 0;
        margin-right: 15px;
    }
    &.smaller {
        .btn-toggle {
            height: 25px;
            width: 55px;
        }
        .dial {
            height: 25px;
            width: 25px;
        }
    }
}
