.table-responsive {
    .table {
        min-width: map-get($grid-breakpoints, 'lg');
    }
}

table.table {
    margin-bottom: 0;
    font-size: $font-size-base * 0.875;
    caption {
        caption-side: top;
    }
    thead{
        th {
            border-right: solid 1px;
            border-left: solid 1px;
            color: white !important;
            border-bottom: 0;
            border-top: 0;
            &:first-of-type {
                border-left: 0;
            }
            &:last-of-type {
                border-right: 0;
            }
        }
    }

    tbody, tfoot {
        tr {
            border-bottom: 1px solid $overlay-15;
            &:last-of-type {
                //border-bottom: 0px;
            }
        }
        td {
            vertical-align: middle !important;
            border-top: unset;
        }
        tr.revealable {
            background-color: $eggplant-lightest-faded;
            td {
                vertical-align: top ! important;
            }
        }
        .tc-icon {
            font-size: 25px;
            text-align: center;

            i {
                border: 1px solid;
                padding: 5px 8px;
                margin-left: 5px;
                margin-right: 5px;
                cursor: pointer;
            }

            .no-border {
                border: none;
            }

            .no-pointer {
                cursor: none;
            }
        }
    }
}

.table {
    &--end {
        padding: $padding-main 15px 0;
        //background-color: $eggplant-lightest;
        label {
            @include standard-label;
            margin-bottom: 0;
        }
    }
}
.table {
    &-wrapper {
        position: relative;
    }
    &-loader {
        @include loader;
    }
}

.table-actions {
    min-width: 50px;
    width: 50px;
    &--header {

    }
    &--actions {
        padding: 0 ! important;
        position: relative;
        border-right: 1px solid $eggplant-lightest;
        .dropdown {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            .dropdown-toggle, .single-action {
                height: 100%;
                border-radius: 0;
                border-bottom: 0;
                min-width: 100%;
                &:hover {
                    background-color: $eggplant-lightest;
                }
                &:after {
                    display: none;
                }
            }
            .dropdown-menu {
                padding: 0;
                overflow: hidden;
            }
            .dropdown-item {
                padding: 0.5rem 1.0rem;
                border-bottom: 1px solid $eggplant-lightest;
                &:active {
                    background-color: $eggplant;
                }
            }
            .single-action {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                appearance: none;
                border: none;
                background-color: unset;
            }
        }
    }
    &--buttons {
        list-style: none;
        display: flex;
        margin-bottom: 0;
        padding: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        li {
            display: flex;
        }
        button {
            min-width: 50px;
            appearance: none;
            border: unset;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.page-item {
    &:first-child {
        .page-link {
            margin-right: 2px;
        }
    }

    &:not(:first-child):not(:last-child) {
        .page-link {
            margin-right: 2px;
        }
    }

    &:last-child {
        .page-link {
            margin-right: 0;
        }
    }
}

.pagination-container {
    flex: 1;
    display: flex;
    justify-content: center;
    .pagination {
        margin: 0;
    }
}
