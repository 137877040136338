/*
|--------------------------------------------------------------------------
| Override Bootstrap variables.
|--------------------------------------------------------------------------
|
| These variables should be kept inline with the variables we've declared
| in the main platform for consistency between our implementations.
|
| These variables can be found:
|   order/node_modules/bootstrap/scss/_variables.scss
|
*/

// Colour overrides

$eggplant                   : #3B4559;
$eggplant-lightest          : #DCE0E8;
$eggplant-lightest-faded    : #DCE0E867;
$eggplant-darkest           : #232934;
$eggplant-light             : #B5BDCE;
$eggplant-mid               : #4F5D78;

$logo-green                 : #76BC21;
$warning                    : #FFA640;
$red                        : #FE0505;
$white                      : #FFFFFF;

$overlay-05                 : rgba(0, 0, 0, 0.05);
$overlay-10                 : rgba(0, 0, 0, 0.10);
$overlay-15                 : rgba(0, 0, 0, 0.15);
$overlay-25                 : rgba(0, 0, 0, 0.25);
$overlay-50                 : rgba(0, 0, 0, 0.50);
$overlay-90                 : rgba(0, 0, 0, 0.90);
$screen-15                  : rgba(255, 255, 255, 0.15);

$primary                    : $logo-green;
$secondary                  : $eggplant-light;
$tertiary                   : $eggplant;
$success                    : $logo-green;
$danger                     : $red;

$tertiary-darker: $eggplant-darkest;

$theme-colors: (
    // Because we're still using Bootforms which provides Bootstrap 3 markup
    // we add a default theme in to account for the buttons spat out from there.
    "default"               : $primary,
    "primary"               : $primary,
    "secondary"             : $secondary,
    "tertiary"              : $tertiary,
    "success"               : $logo-green,
    "warning"               : $warning,
    "danger"                : $danger
);

$body-bg                    : $eggplant-lightest !default;
$bg-dark                    : $eggplant;

$font-size-base             : 1rem;
$font-family-sans-serif     : 'Lato', 'Open Sans', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Forms
$input-btn-padding-y:         .5rem !default;
$input-btn-padding-x:         .5rem !default;

// Headings

$h1-font-size:                $font-size-base * 1.75; // 2.1875rem -> 28px
$h2-font-size:                $font-size-base * 1.25; // 1.5rem -> 20px
$h3-font-size:                $font-size-base; // 1.25rem -> 20px
$h4-font-size:                $font-size-base; // 1.25rem -> 20px
$h5-font-size:                $font-size-base * 0.875; // 0.875rem -> 14px
$h6-font-size:                $font-size-base * 0.875; // 0.875rem -> 14px

// Buttons & Nav

$dropdown-link-hover-bg  : $eggplant-lightest-faded !default;

// Tables

$table-dark-color:            $white;
$table-dark-bg:               $eggplant;
$table-dark-border-color:     $white;

// Toasts

$toast-min-width: 150px;
$toast-margin-from-corner: 5px;

// Layout
$padding-main               : 1rem * 1.5625; // 25px
$margin-main                : $padding-main;

$padding-main-compressed    : $padding-main / 2; // 12.5px
$margin-main-compressed     : $padding-main-compressed;

$box-shadow-tight: 0 2px 3px 0px $overlay-10, 0 0 1px 1px $overlay-05;


@mixin loader() {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background-color: $primary;
    animation: infinite ease-in-out section-loader-keyframes 1000ms;
    @keyframes section-loader-keyframes {
        0%      {left: 0%; width: 5%;}
        25%     {width: 10%}
        50%     {width: 30%}
        75%     {width: 25%}
        100%    {left: 100%; width: 0%}
    }
}

// Finally we import bootstrap
@import "~bootstrap/scss/bootstrap";

@import "scss/mixins";
@import "scss/layout";
@import "scss/buttons";
@import "scss/forms";
@import "scss/tables";
@import "scss/toasts";
