.toast-container {
  position: sticky;
  z-index: 1055;
  top: 0;
}

.toast-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  margin: $toast-margin-from-corner;
}

.toast {
    min-width: $toast-min-width;
    background-color: unset;
    border: 1px solid #B5BDCE;
    box-shadow: 0 0 0.25rem 0.75rem #B5BDCE;
    border-radius: 2px;
    &.show {
        display: flex;
    }
    .toast-content {
        flex: 1 1 auto;
    }
    .toast-header {
        //background-color: black;
        text-transform: uppercase;
        padding: 0.15rem 0.75rem;
        font-size: $h5-font-size;
    }
    .toast-body {
        padding: 0.75rem 1.75rem;
    }
    .close {
        opacity: 1;
    }
}

.Toastify__toast {
  padding: 0!important;

  &.Toastify__toast--default,
  &.Toastify__toast--error,
  &.Toastify__toast--warning,
  &.Toastify__toast--success,
  &.Toastify__toast--info {
      background: $white !important;
      overflow: hidden;
    .toast-body {
        color: $eggplant-darkest !important;
    }
  }
    &.Toastify__toast--default,
  &.Toastify__toast--error,
  &.Toastify__toast--warning,
  &.Toastify__toast--success,
  &.Toastify__toast--info {
    .toast-header,
    .toast-close {
      color: white !important;
    }
  }

  &.Toastify__toast--default {
    .toast-header,
    .toast-close {
        background: $info;
    }
  }
  &.Toastify__toast--error {
      .toast-header {
          background-color: $danger;
          .error {
              display: block;
          }
      }
  }
  &.Toastify__toast--warning {
      .toast-header {
          background-color: $warning;
          .warning {
              display: block;
          }
      }
  }
  &.Toastify__toast--info {
      .toast-header {
          background-color: $blue;
          .info {
              display: block;
          }
      }
  }
  &.Toastify__toast--success {
      .toast-header {
          background-color: $success;
          .success {
              display: block;
          }
      }
  }
}
