h1 {
    font-size: $h1-font-size;
    font-weight: 400;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        font-size: $h1-font-size * 0.80;
    }
}
h2 {
    font-size: $h2-font-size;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        font-size: $h2-font-size * 0.85;
    }
}
h3 {
    font-size: $h3-font-size;
    font-weight: 600;
    text-transform: uppercase;
    @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        font-size: $h3-font-size * 0.85;
    }
}
h4 {
    font-size: $h4-font-size;
    font-weight: 900;
    @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        font-size: $h4-font-size * 0.85;
    }
}
h5 {
    font-size: $h5-font-size;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 0;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.flex-one {
    flex: 1 0;
}

.bg-dark {
    background-color: $bg-dark ! important;
}

.field-block {
    padding: $padding-main;
    background-color: $white;
    margin: 15px 0;
    border-radius: $border-radius;
    box-shadow: 0 2px 3px 0 $overlay-10;
    position: relative;
    //overflow: hidden;
    &.compressed {
        padding: $padding-main-compressed;
        margin: 15px $margin-main-compressed;
    }
    &.nested {
        background-color: unset;
        padding: 0;
        box-shadow: unset;
        margin-top: $margin-main;
        > .section-heading {
            margin-bottom: 0;
        }
    }
    &.full-width {
        $full-width-padding: 1rem * 0.25;//4px;
        padding: {
            left: $full-width-padding;
            right: $full-width-padding;
        }
        > .field-row {
            padding: 0 $padding-main - $full-width-padding;
        }
        > h* {
            padding: 0 $padding-main - $full-width-padding;
        }
        .section-heading {
            padding: 0 $padding-main - $full-width-padding;
        }
        .subsection-heading {
            padding: 0 $padding-main - $full-width-padding;
        }
        .empty-container {
            margin: 0 $margin-main - $full-width-padding;
        }
    }
    .field-row:last-of-type:not(.push-bottom) {
        //margin-bottom: 0;
    }
    .field-block {
        box-shadow: unset;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: $padding-main - 1rem * 0.25;
        padding-bottom: $padding-main + 1rem * 0.375;
        border-bottom: 3px solid $eggplant;
        border-radius: 0;
        &.first {
            padding-top: 0;
        }
        &.last {
            padding-bottom: 0;
            border-bottom: 0;
        }
        div:first-of-type {
            margin-top: 0;
        }
        .field-block {
            border-bottom: 1px solid $eggplant;
            &.last {
                border-bottom: 0;
            }
        }
    }
    .table-wrapper {
        $offset: 1rem * 0.25;
        margin-left: -$margin-main + $offset;
        margin-right: -$margin-main + $offset;
        position: relative;
        .empty-container {
            margin-left: $margin-main - $offset;
            margin-right: $margin-main - $offset;
        }
        @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
            margin-left: -$margin-main-compressed + $spacer + $offset;
            margin-right: -$margin-main-compressed + $spacer + $offset;
        }
    }
    .section-loader--wrapper {
        position: absolute;
        top: 0;
        height: 5px;
        left: 0;
        right: 0;
        overflow: hidden;
        .section-loader {
            @include loader;
        }
    }
    &.push-for-footer {
        margin-bottom: 85px;
    }
    @media (max-width: map-get($grid-breakpoints, 'md')) {
        padding: $padding-main-compressed $padding-main-compressed - $spacer;
        .field-row:last-of-type .form-group {
            //margin-bottom: 0;
        }
    }
}

.page-heading {
    padding: 0 $padding-main;
    margin: $margin-main 0 $margin-main-compressed;
    h1 {
        padding: 0;
    }
    &.wab { // with action button
        display: flex;
        align-items: center;
        h1 {
            flex: 1;
            margin-right: 25px;
            margin-bottom: 0;
        }
    }
    &.wbm { // with bottom margin
        margin-bottom: $margin-main;
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        margin-top: $margin-main-compressed;
        padding: 0 $padding-main-compressed;
    }
}

.section-heading {
    display: flex;
    align-items: center;
    margin-bottom: $margin-main - 1rem * 0.625;
    h2 {
        flex: 1 1;
        margin-bottom: 0;
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        margin-bottom: $margin-main-compressed;
    }
}
.subsection-heading {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    &.ntm {
        margin-top: 0;
    }
    h3, h4 {
        flex: 1 1;
        margin-bottom: 0px;
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        margin-top: $margin-main-compressed;
    }
}

.list-item {
    margin-bottom: 1px;
    border-radius: $border-radius;
    overflow: hidden;
    a {
        position: relative;
        display: flex;
        min-height: 75px;
        color: $eggplant-darkest;
        background-color: $eggplant-lightest-faded;
        .list-item-container {
            position: relative;
            flex: 1 1;
            display: flex;
            align-items: center;
            padding: 0 $padding-main / 2;
            h4 {
                flex: 1 1;
                margin-bottom: 0;
            }
            svg {
                display: none;
            }
        }
        .list-item-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $eggplant;
            min-width: 75px;
            font-size: 1.5rem;
            color: $white;
        }
        .list-item-bg {
            position: absolute;
            width: 0;
            left: 0;
            top: 0;
            bottom: 0;
            transition: background-color ease 250ms, width ease 150ms;
        }
        &:hover {
            color: $white;
            text-decoration: unset;
            .list-item-container {
                svg {
                    display: block;
                }
            }
            .list-item-bg {
                width: 100%;
                background-color: $eggplant;
            }
        }
    }
}

.container {
    &-size {
        &-sm {
            max-width: 500px;
        }
        &-md {
            max-width: 800px;
        }
        &-regular {
            max-width: 1200px;
        }
    }
}

.empty-container {
    $spacing: 20px;
    background-color: $eggplant-lightest;
    padding: ($padding-main * 2);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow-tight;
    &--icon {
        display: flex;
        justify-content: flex-end;
        padding-right: $spacing;
        color: $eggplant;
    }
    &--details {
        //padding-left: $spacing;
        > div {
            margin: 10px 0;
        }
    }
    &--title {
        font-style: italic;
        font-weight: 400;
        &.no-push {
            margin-bottom: 0;
        }
    }
    @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        padding: $padding-main-compressed;
    }
}

.detail-row {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    .detail-row--detail {
        flex: 1 1;
        display: flex;
        align-items: center;
        background-color: $eggplant-lightest-faded;
        padding: 0.8rem;
        &:not(:last-of-type) {
            margin-right: 1px;
        }
        .label {
            flex: 1;
            //@include standard-label;
            margin-bottom: 0;
            &.right {
                text-align: right;
                padding-right: 5px;
            }
            &.left {
                flex: unset;
                padding-right: 5px;
            }
        }
        .detail {
            font-weight: 800;
            font-size: 1rem;
        }
        &.hover:hover {
            background-color: $eggplant-lightest-faded;
            cursor: pointer;
        }
        &.no-label {
            .detail {
                font-weight: normal;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }
    &.condensed {
        .detail-row--detail {
            padding: 0.4rem;
        }
    }
    &.condensed-y {
        .detail-row--detail {
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
        }
    }
    &.condensed-text {
        .detail-row--detail {
            .detail, .label {
                font-size: 0.8rem;
            }
        }
    }
}

.paginated-container {
    max-height: 340px;
    overflow-y: scroll;
    &.overflow {
        box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
    }
}

.order-items {
    &--container {
        .order {
            &--container {
                display: flex;
                margin-bottom: 1px;
                background-color: $eggplant-lightest-faded;
                border-radius: $border-radius;
                overflow: hidden;
                &--status {
                    display: flex;
                    flex-direction: column;
                    margin-right: 1px;
                    position: relative;
                    overflow: hidden;
                    min-width: 100px;
                    &--icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 4rem;
                        background-color: $eggplant;
                        flex: 1;
                        color: $white;
                        //max-height: 100px;
                        &--outer {
                            position: relative;
                            line-height: 0;
                        }
                        &--inner {
                            position: absolute;
                            top: 70%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 1rem;
                            color: $eggplant;
                        }
                    }
                    &--state {
                        text-align: center;
                        padding: 5px;
                        position: absolute;
                        top: 50%;
                        left: -15px;
                        right: -15px;
                        transform: translateY(-50%) rotate(-14deg);
                        &.finalized {
                            background-color: rgba($green, 0.85);
                            color: $white;
                        }
                    }
                }
                &--details {
                    flex: 1;
                    //padding: 10px 0;
                    .row {
                        margin-left: 0;
                        margin-right: 0;
                    }
                    &--order-heading {
                        display: flex;
                        padding: 25px;
                        h2, h3 {
                            margin-bottom: 0;
                        }
                        &--text {
                            flex: 1 0;
                        }
                        &--action {
                            margin-left: 15px;
                        }
                        @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
                            display: block;
                            padding: 0px;
                            &--text {
                                margin: 20px 0;
                            }
                            &--action {
                                margin-left: 0px;
                            }
                        }
                    }
                    p {
                        margin-bottom: 0;
                        max-width: 400px;
                        margin-top: 10px;
                        color: $eggplant-mid;
                    }
                    &--vitals {
                        background-color: $eggplant;
                        color: $white;
                        margin: 1px 0;
                        text-align: center;
                        border-top-left-radius: $border-radius;
                        border-top-right-radius: $border-radius;
                        overflow: hidden;
                        h5 {
                            margin-bottom: 0;
                            padding: 8px;
                            flex: 1 0;
                            display: flex;
                            justify-content: space-between;
                            span {
                                font-weight: 900;
                            }
                            @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
                                text-align: left;
                            }
                        }
                        > div {
                            display: flex;
                            padding-left: 0;
                            padding-right: 0;
                        }
                        > div:not(:last-of-type) {
                            border-right: 1px $white solid;
                        }
                        &--icon {
                            background-color: rgba($green, 1);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $eggplant;
                            min-width: 32px
                        }
                        @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
                            margin-bottom: 0;
                            > div {
                                border-bottom: 1px solid $white;
                            }
                            > div:nth-of-type(even) {
                                border-right: 0px;
                            }
                        }
                        @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
                            > div {
                                border-right: 0px ! important;
                            }
                        }
                    }
                }
                @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
                    flex-direction: column;
                    padding: 25px;
                    &--status {
                        min-width: 75px;
                        width: 75px;
                        min-height: 75px;
                        &--icon {
                            font-size: 3rem;
                            &--outer {
                                line-height: 0;
                            }
                        }
                    }
                    &--details {
                        &--order-heading {
                            &--text {
                                padding-left: 0;
                                padding-right: 0;
                            }
                            &--action {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
        .items {
            &--container {
                overflow-y: scroll;
                border-right: 1px solid rgba($eggplant-light, 0.65);
                border-left: 1px solid rgba($eggplant-light, 0.65);
                &--wrapper {
                    min-width: 950px;
                }
                .item {
                    &--container {
                        display: flex;
                        border-bottom: 1px solid rgba($eggplant-light, 0.65);
                        &:not(:last-of-type) {
                            //margin-bottom: 1px;
                        }
                        &--icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 15px;
                            background-color: $eggplant-lightest;
                            margin-right: 1px;
                            color: $white;
                        }
                        &--details {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            &.row {
                                margin-left: 0;
                                margin-right: 0;
                            }
                            h6 {
                                margin-bottom: 0;
                            }
                            .detail {
                                &--item-name {
                                    span {
                                        font-style: italic;
                                    }
                                }
                                &--item-total {
                                    text-align: right;
                                    h6 {
                                        font-weight: 900;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.tenant-logo-container {
    min-height: 125px;
    width: 100%;
    background-color: $eggplant-lightest-faded;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $eggplant;
    font-size: 3em;
    position: relative;
    overflow: hidden;
    img {
        max-height: 125px;
        margin-bottom: 0;
    }
    &.has-image {
        background-color: unset;
    }
}

.recent-orders {
    .recent-order {
        margin-bottom: $margin-main;
        margin-right: 50px;
        max-width: 300px;
        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .action {
            justify-content: center;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.alert {
    .icon {
        padding-right: 1.25rem;
    }
}

.user-profile--dropdown {
    .dropdown-menu {
        right: 0;
        left: unset;
    }
}
